import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Github = makeShortcode("Github");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Flash swaps are an integral feature of Uniswap V2. In fact, under the hood, all swaps are actually flash swaps! This simply means that pair contracts send output tokens to the recipient `}<em parentName="p">{`before`}</em>{` enforcing that enough input tokens have been received. This is slightly atypical, as one might expect a pair to ensure it’s received payment before delivery. However, because Ethereum transactions are `}<em parentName="p">{`atomic`}</em>{`, we can roll back the entire swap if it turns out that the contract hasn’t received enough tokens to make itself whole by the end of the transaction.`}</p>
    <p>{`To see how this all works, let’s start by examining the interface of the `}<inlineCode parentName="p">{`swap`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swap(uint amount0Out, uint amount1Out, address to, bytes calldata data);
`}</code></pre>
    <p>{`For the sake of example, let’s assume that we’re dealing with a DAI/WETH pair, where DAI is `}<inlineCode parentName="p">{`token0`}</inlineCode>{` and WETH is `}<inlineCode parentName="p">{`token1`}</inlineCode>{`. `}<inlineCode parentName="p">{`amount0Out`}</inlineCode>{` and `}<inlineCode parentName="p">{`amount1Out`}</inlineCode>{` specify the amount of DAI and WETH that the `}<inlineCode parentName="p">{`msg.sender`}</inlineCode>{` wants the pair to send to the `}<inlineCode parentName="p">{`to`}</inlineCode>{` address (one of these amounts may be 0). At this point you may be wondering how the contract `}<em parentName="p">{`receives`}</em>{` tokens. For a typical (non-flash) swap, it’s actually the responsibility of `}<inlineCode parentName="p">{`msg.sender`}</inlineCode>{` to ensure that enough WETH or DAI has `}<em parentName="p">{`already been sent`}</em>{` to the pair before `}<inlineCode parentName="p">{`swap`}</inlineCode>{` is called (in the context of trading, this is all handled neatly by a router contract). But when executing a flash swap, `}<em parentName="p">{`tokens do not need to be sent to the contract before calling `}<inlineCode parentName="em">{`swap`}</inlineCode></em>{`. Instead, they must be sent from within a `}<em parentName="p">{`callback function`}</em>{` that the pair triggers on the `}<inlineCode parentName="p">{`to`}</inlineCode>{` address.`}</p>
    <h1 {...{
      "id": "triggering-a-flash-swap",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#triggering-a-flash-swap",
        "aria-label": "triggering a flash swap permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Triggering a Flash Swap`}</h1>
    <p>{`To differentiate between the “typical” trading case and the flash swap case, pairs use the `}<inlineCode parentName="p">{`data`}</inlineCode>{` parameter. Specifically, if `}<inlineCode parentName="p">{`data.length`}</inlineCode>{` equals 0, the contract assumes that payment has already been received, and simply transfers the tokens to the `}<inlineCode parentName="p">{`to`}</inlineCode>{` address. But, if `}<inlineCode parentName="p">{`data.length`}</inlineCode>{` is greater than 0, the contract transfers the tokens and then calls the following function on the `}<inlineCode parentName="p">{`to`}</inlineCode>{` address:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function uniswapV2Call(address sender, uint amount0, uint amount1, bytes calldata data);
`}</code></pre>
    <p>{`The logic behind this identification strategy is simple: the vast majority of valid flash swap use cases involve interactions with external protocols. The best way to pass information dictating how these interactions happen (function arguments, safety parameters, addresses, etc.) is via the `}<inlineCode parentName="p">{`data`}</inlineCode>{` parameter. It’s expected that `}<inlineCode parentName="p">{`data`}</inlineCode>{` will be `}<inlineCode parentName="p">{`abi.decode`}</inlineCode>{`d from within `}<inlineCode parentName="p">{`uniswapV2Call`}</inlineCode>{`. In the rare case where no data is required, callers should ensure that `}<inlineCode parentName="p">{`data.length`}</inlineCode>{` equals 1 (i.e. encode a single junk byte as `}<inlineCode parentName="p">{`bytes`}</inlineCode>{`), and then ignore this argument in `}<inlineCode parentName="p">{`uniswapV2Call`}</inlineCode>{`.`}</p>
    <p>{`Pairs call `}<inlineCode parentName="p">{`uniswapV2Call`}</inlineCode>{` with the `}<inlineCode parentName="p">{`sender`}</inlineCode>{` argument set to the `}<inlineCode parentName="p">{`msg.sender`}</inlineCode>{` of the `}<inlineCode parentName="p">{`swap`}</inlineCode>{`. `}<inlineCode parentName="p">{`amount0`}</inlineCode>{` and `}<inlineCode parentName="p">{`amount1`}</inlineCode>{` are simply `}<inlineCode parentName="p">{`amount0Out`}</inlineCode>{` and `}<inlineCode parentName="p">{`amount1Out`}</inlineCode>{`.`}</p>
    <h1 {...{
      "id": "using-uniswapv2call",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-uniswapv2call",
        "aria-label": "using uniswapv2call permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using uniswapV2Call`}</h1>
    <p>{`There are several conditions that should be checked in all `}<inlineCode parentName="p">{`uniswapV2Call`}</inlineCode>{` functions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function uniswapV2Call(address sender, uint amount0, uint amount1, bytes calldata data) {
  address token0 = IUniswapV2Pair(msg.sender).token0(); // fetch the address of token0
  address token1 = IUniswapV2Pair(msg.sender).token1(); // fetch the address of token1
  assert(msg.sender == IUniswapV2Factory(factoryV2).getPair(token0, token1)); // ensure that msg.sender is a V2 pair
  // rest of the function goes here!
}
`}</code></pre>
    <p>{`The first 2 lines simply fetch the token addresses from the pair, and the 3rd ensures that the `}<inlineCode parentName="p">{`msg.sender`}</inlineCode>{` is an actual Uniswap V2 pair address.`}</p>
    <h1 {...{
      "id": "repayment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#repayment",
        "aria-label": "repayment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Repayment`}</h1>
    <p>{`At the end of `}<inlineCode parentName="p">{`uniswapV2Call`}</inlineCode>{`, contracts must return enough tokens to the pair to make it whole. Specifically, this means that the product of the pair reserves after the swap, discounting all token amounts sent by 0.3% LP fee, must be greater than before.`}</p>
    <h2 {...{
      "id": "multi-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multi-token",
        "aria-label": "multi token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multi-Token`}</h2>
    <p>{`In the case where the token withdrawn is `}<em parentName="p">{`not`}</em>{` the token returned (i.e. DAI was requested in the flash swap, and WETH was returned, or vice versa), the fee simplifies to the simple swap case. This means that the standard `}<inlineCode parentName="p">{`getAmountIn`}</inlineCode>{` pricing function should be used to calculate e.g. the amount of WETH that must be returned in exchange for the amount of DAI that was requested out.`}</p>
    <p>{`This fee calculation is an excellent reason to use Uniswap flash swaps - if you pay for your flash swap in the corresponding pair token, it’s free!`}</p>
    <h2 {...{
      "id": "single-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#single-token",
        "aria-label": "single token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Single-Token`}</h2>
    <p>{`In the case where the token withdrawn is the `}<em parentName="p">{`same`}</em>{` as the token returned (i.e. DAI was requested in the flash swap, used, then returned, or vice versa with WETH), the following condition must be satisfied:`}</p>
    <p><inlineCode parentName="p">{`DAIReservePre - DAIWithdrawn + (DAIReturned * .997) >= DAIReservePre`}</inlineCode></p>
    <p>{`It may be more intuitive to rewrite this formula in terms of a “fee” levied on the `}<em parentName="p">{`withdrawn`}</em>{` amount (despite the fact that Uniswap always levies fees on input amounts, in this case the `}<em parentName="p">{`returned`}</em>{` amount, here we can simplify to an effective fee on the `}<em parentName="p">{`withdrawn`}</em>{` amount). If we rearrange, the formula looks like:`}</p>
    <p><inlineCode parentName="p">{`(DAIReturned * .997) - DAIWithdrawn >= 0`}</inlineCode></p>
    <p><inlineCode parentName="p">{`DAIReturned >= DAIWithdrawn / .997`}</inlineCode></p>
    <p>{`So, the effective fee on the withdrawn amount is `}<inlineCode parentName="p">{`.003 / .997 ≈ 0.3009027%`}</inlineCode>{`.`}</p>
    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h1>
    <p>{`For further exploration of flash swaps, see the `}<a href='/whitepaper.pdf' target='_blank' rel='noopener noreferrer'>{`whitepaper`}</a>{`.`}</p>
    <h1 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h1>
    <p>{`A fully functional example of flash swaps is available: `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-v2-periphery/blob/master/contracts/examples/ExampleFlashSwap.sol"
      }}><inlineCode parentName="a">{`ExampleFlashSwap.sol`}</inlineCode></a>{`.`}</p>
    <Github href="https://github.com/Uniswap/uniswap-v2-periphery/blob/master/contracts/examples/ExampleSwapToPrice.sol" mdxType="Github">ExampleSwapToPrice.sol</Github>
    <h1 {...{
      "id": "interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#interface",
        "aria-label": "interface permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interface`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`import '@uniswap/v2-core/contracts/interfaces/IUniswapV2Callee.sol';
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`pragma solidity >=0.5.0;

interface IUniswapV2Callee {
  function uniswapV2Call(address sender, uint amount0, uint amount1, bytes calldata data) external;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      